(function (exports) {
  var commonClasses = ['padding-x-4']
  var warningClasses = ['orangeStatusElement', 'darkStatusElementColorText']
  var errorClasses = ['redStatusElement', 'errorStatusElementColorText']
  var adoptedClasses = []

  exports.showError = function (error, errorPlaceholderElement, inModal) {
    var errorKey = decideLocalizedMessage(error)
    var errorMessage = getLocalizedErrorMessage(errorKey)
    decideIfErrorOrWarning(error)

    if (inModal) {
      showErrorInModal(errorPlaceholderElement, errorMessage)
    } else {
      showErrorFixed(errorPlaceholderElement, errorMessage)
    }
  }

  exports.hideError = function (errorPlaceholderElement) {
    var hasError = true

    for (var i = 0; i < adoptedClasses.length; i++) {
      hasError = hasError && errorPlaceholderElement.hasClass(adoptedClasses[i])
    }

    if (hasError && adoptedClasses.length > 0) {
      errorPlaceholderElement.removeClass(adoptedClasses.join(' '))
      errorPlaceholderElement.html('')
    }
  }

  function showErrorFixed (errorPlaceholderElement, message) {
    errorPlaceholderElement.addClass(adoptedClasses.join(' '))
    errorPlaceholderElement.html(message)
  }

  function showErrorInModal (errorPlaceholderElement, message) {
    $.when(
      errorModalTemplate
    ).done(function (errorModalTemplate) {
      var compiledErrorTemplate = ejs.compile(errorModalTemplate)
      var errorHtml = compiledErrorTemplate({})
      errorPlaceholderElement.html(errorHtml)

      $('#error_message_placeholder').html(message)

      $('#errorModal').modal()
    })
  }

  function getLocalizedErrorMessage (errorKey) {
    var localizedErrorMessage = ''

    $.when(
      errorMessagesLocales
    ).done(function (errorMessagesLocales) {
      localizedErrorMessage = errorMessagesLocales[errorKey]
    })

    return localizedErrorMessage
  }

  function decideLocalizedMessage (error) {
    if (Offline.state === 'down') {
      return 'no_internet_connection'
    }

    if (error.status === 403) {
      return 'user_not_in_session'
    }

    if (error.readyState === 0 && error.status === 0) {
      return 'refresh_needed'
    }

    var errorKey = commonErrorHandling.decideLocalizedMessage(error)

    return errorKey || 'generic'
  }

  function decideIfErrorOrWarning (error) {
    adoptedClasses = commonClasses
    if (error.readyState === 0 && error.status === 0 && Offline.state !== 'down') {
      adoptedClasses = adoptedClasses.concat(warningClasses)
    } else {
      adoptedClasses = adoptedClasses.concat(errorClasses)
    }
  }

  var errorMessagesLocales = $.get('/errorMessagesLocales')
  var errorModalTemplate = $.get('/static/error_modal.ejs')
}(typeof exports === 'undefined' ? this.clientErrorHandling = {} : exports))
